import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './App.css';
import 'typeface-roboto';
import LogoutPage from '../../pages/Logout';
import DashboardPage from '../../pages/Dashboard';
import ClientsPage from '../../pages/Clients';
import FormsPage from '../../pages/Forms';
import Page404 from '../../pages/404';
import AppBar from '../../components/AppBar/AppBar';
import Drawer from '../../components/Drawer/Drawer';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Callback from '../../components/Callback/Callback';
import Ping from '../../components/Ping/Ping';


const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  contentLogged: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 5,
    marginTop: 55,
    marginBottom: 65,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)',
      marginLeft: 240,
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isLoggedIn: PropTypes.func.isRequired,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerToggle() {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  render() {
    const { classes, isLoggedIn } = this.props;
    const { mobileOpen } = this.state;

    const AppBarComponent = isLoggedIn() ? (
      <AppBar
        toggleHandler={this.handleDrawerToggle}
      />
    ) : null;
    const DrawerComponent = isLoggedIn() ? (
      <Drawer
        mobileOpen={mobileOpen}
        toggleHandler={this.handleDrawerToggle}
      />
    ) : null;

    return (
      <Router>
        <div>
          <div className={classes.root}>
            {AppBarComponent}
            {DrawerComponent}
            <main
              className={isLoggedIn() ? classes.contentLogged : classes.content}
            >
              <Switch>
                <Route exact path="/logout" component={LogoutPage} />
                <Route exact path="/callback" component={Callback} />
                <PrivateRoute
                  exact
                  path="/"
                  component={DashboardPage}
                  api={this.api}
                />
                <PrivateRoute
                  exact
                  path="/clients"
                  component={ClientsPage}
                  api={this.api}
                />
                <PrivateRoute
                  exact
                  path="/forms"
                  component={FormsPage}
                  api={this.api}
                />
                <Route exact path="/ping" component={Ping} />
                <Route component={Page404} />
              </Switch>
            </main>
          </div>
        </div>
      </Router>
    );
  }
}

App.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(App);
