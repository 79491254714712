import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};


const ClientsPage = ({ classes }) => (
  <div className={classes.list}>
    <h1>
      Hello There, you are logged in and this is the Clients page.
    </h1>
  </div>
);


ClientsPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(ClientsPage);
