export const DASHBOARD = 'dashboard';
export const CLIENTS = 'clients';
export const FORMS = 'forms';
export const FORMS_EDIT = 'forms_edit';
export const LOGOUT = 'logout';

export const KEY_LABEL = {
  [DASHBOARD]: 'Dashboard',
  [CLIENTS]: 'Clients',
  [FORMS]: 'Forms',
  [FORMS_EDIT]: 'Edit Form',
  [LOGOUT]: 'Logout',
};

export const PATH_NAME = {
  [DASHBOARD]: '/',
  [CLIENTS]: '/clients',
  [FORMS]: '/forms',
  [FORMS_EDIT]: '/forms/edit',
  [LOGOUT]: '/logout',
};

export const PATH_LABEL = {
  [PATH_NAME[DASHBOARD]]: 'Dashboard',
  [PATH_NAME[CLIENTS]]: 'Clients',
  [PATH_NAME[FORMS]]: 'Forms',
  [PATH_NAME[FORMS_EDIT]]: 'Edit Form',
  [PATH_NAME[LOGOUT]]: 'Logout',
};

export const PATHS = [
  {
    key: DASHBOARD,
    path: PATH_NAME[DASHBOARD],
    text: KEY_LABEL[DASHBOARD],
  },
  {
    key: CLIENTS,
    path: PATH_NAME[CLIENTS],
    text: KEY_LABEL[CLIENTS],
  },
  {
    key: FORMS,
    path: PATH_NAME[FORMS],
    text: KEY_LABEL[FORMS],
  },
];
