import axios from 'axios';

import config from '../config';

const createAPI = (apiConf) => {
  const client = axios.create(apiConf);

  const api = {
    clients: {
      get(params, token = '') {
        return client.request({
          method: 'get',
          url: '/clients',
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
      },
    },
    example: {
      get(params, token = '') {
        return client.request({
          method: 'get',
          url: '/example',
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
      },
      getOne(params, token) {
        return client.request({
          method: 'get',
          url: '/example/',
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
      },
      create(data, token) {
        return client.request({
          method: 'post',
          url: '/example',
          headers: { Authorization: `Bearer ${token}` },
          data,
        });
      },
    },
  };
  return api;
};

export default createAPI(config.api);
