import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClientsTable from '../containers/ClientsTable/ClientsTable';


const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const DashboardPage = ({ classes, history }) => (
  <div className={classes.list}>
    <ClientsTable history={history} paginate search />
  </div>
);


DashboardPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(DashboardPage);
