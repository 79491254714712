import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Hidden,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ExitToApp,
} from '@material-ui/icons';
import {
  PATH_LABEL,
  PATH_NAME,
  FORMS_EDIT,
  LOGOUT,
} from '../../constants/paths';
import withAuth from '../../consumers/withAuth';


const styles = theme => ({
  barRoot: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  barItem: {
    alignSelf: 'center',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  appBar: {
    position: 'fixed',
    marginLeft: (theme.drawer) ? theme.drawer.width : 240,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${(theme.drawer) ? theme.drawer.width : 240}px)`,
    },
  },
  navIconHide: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  colorPrimary: {
    backgroundColor: '#ff9930',
  },
  barColorPrimary: {
    backgroundColor: '#ffcc97',
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  toggleHandler: PropTypes.func.isRequired,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleLogout = this.handleLogout.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  getTitle() {
    const { history } = this.props;
    const { pathname } = history.location;

    let title = PATH_LABEL[pathname];
    if (title === undefined && pathname.indexOf(PATH_NAME[FORMS_EDIT]) !== -1) {
      title = PATH_LABEL[PATH_NAME[FORMS_EDIT]];
    }
    return title;
  }

  handleLogout() {
    const { auth, history } = this.props;
    history.push(PATH_NAME[LOGOUT]);
    auth.logout();
  }

  render() {
    const {
      classes,
      toggleHandler,
    } = this.props;

    return (
      <MuiAppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={toggleHandler}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.barRoot}>
            <div className={classes.barItem}>
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                {this.getTitle()}
              </Typography>
            </div>
            <div className={classes.barItem}>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="Logout"
                  onClick={this.handleLogout}
                  className={classes.navIconHide}
                >
                  <ExitToApp />
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <Button
                  color="inherit"
                  aria-label="Logout"
                  className={classes.button}
                  onClick={this.handleLogout}
                >
                  <ExitToApp className={classes.leftIcon} />
                  Logout
                </Button>
              </Hidden>
            </div>
          </div>
        </Toolbar>
      </MuiAppBar>
    );
  }
}

AppBar.propTypes = propTypes;
const AppBarWithRouter = withRouter(AppBar);
const AppBarStyled = withStyles(styles, { withTheme: true })(AppBarWithRouter);
export default withAuth(AppBarStyled);
