import React from 'react';
import PropTypes from 'prop-types';
import {
  TablePagination,
  TableRow,
  Hidden,
  Typography,
  Grid,
} from '@material-ui/core';
import moment from 'moment';

import DataTable from '../../components/DataTable/DataTable';
import TableCell from '../../components/DataTable/TableCell';
import SearchForm from '../../components/SearchForm/SearchForm';
import withAuth from '../../consumers/withAuth';
import api from '../../services/api';

const propTypes = {
  paginate: PropTypes.bool,
  showEmptyRows: PropTypes.bool,
  search: PropTypes.bool,
  defaultLimit: PropTypes.number,
};

const defaultProps = {
  paginate: false,
  search: false,
  showEmptyRows: false,
  defaultLimit: 10,
};

class ClientsTable extends React.Component {
  constructor(props) {
    super(props);
    const { defaultLimit } = this.props;
    this.state = {
      data: [],
      limit: defaultLimit,
      filters: {},
      total: 0,
      page: 0,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.renderRows = this.renderRows.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  static getTableHeader() {
    return [
      { id: 1, label: 'ID' },
      { id: 2, label: 'Jobs ID' },
      { id: 3, label: 'Name' },
      { id: 4, label: 'Comments' },
      { id: 5, label: 'Date Created', hidden: { xsDown: true } },
      { id: 6, label: '' },
    ];
  }

  async fetchData(searchFilters = null, reset = false) {
    const { auth, history } = this.props;
    this.setState({ loading: true });
    const { page, limit, filters } = this.state;
    const newFilters = searchFilters || filters;
    const offset = limit * page;
    const params = Object.assign({ offset, limit }, reset ? {} : newFilters);
    try {
      const response = await api.clients.get(params, auth.getToken());
      const { data } = response;
      this.setState({
        data: data.results,
        filters: reset ? {} : newFilters,
        total: data.count,
        loading: false,
      });
    } catch (error) {
      if (error.response.status === 401) {
        this.setState({
          data: [],
          filters: newFilters,
          loading: false,
        }, () => {
          auth.logout();
          history.replace('/logout');
        });
      }
      this.setState({
        data: [],
        filters: newFilters,
        loading: false,
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page }, () => this.fetchData());
  }

  handleChangeRowsPerPage(event) {
    this.setState({ limit: event.target.value }, () => this.fetchData());
  }

  renderRows() {
    const { data } = this.state;
    if (!data.length) {
      return (
        <Grid
          spacing={32}
          container
          alignItems="center"
          justify="center"
        >
          <Grid sm={12} md={12} item>
            <Typography variant="overline" gutterBottom>
              No Data Available
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      data.map(row => (
        <TableRow
          hover
          tabIndex={-1}
          key={row.id}
        >
          <TableCell component="th" scope="row" padding="default">
            {row.id}
          </TableCell>
          <TableCell>
            ACC123
          </TableCell>
          <TableCell>
            {row.name}
          </TableCell>
          <TableCell>
            {row.description}
          </TableCell>
          <Hidden xsDown>
            <TableCell>
              { moment(row.createdAt).format('MMM DD, YYYY') }
            </TableCell>
          </Hidden>
          <TableCell padding="none" isActions />
        </TableRow>
      ))
    );
  }

  renderPagination() {
    const { total, limit, page } = this.state;
    const { defaultLimit } = this.props;
    let rowsPerPageOptions = [5, 10, 25];
    if (defaultLimit > 10) {
      rowsPerPageOptions = [25, 50, 75];
    }
    return (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={limit}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }

  renderSearch() {
    const fields = [
      {
        id: 'jid',
        name: 'jid',
        label: 'Jobs ID',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
      },
      {
        id: 'name',
        name: 'name',
        label: 'Name',
        type: 'text',
        validateAs: 'string',
        defaultVal: '',
        required: false,
      },
      {
        id: 'createdAt',
        name: 'createdAt',
        label: 'Date From',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        id: 'createdAtTo',
        name: 'createdAtTo',
        label: 'Date To',
        type: 'date',
        validateAs: 'string',
        defaultVal: '',
        required: false,
        customProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
    ];

    return (
      <SearchForm
        onSubmit={this.fetchData}
        onReset={() => this.fetchData(null, true)}
        fields={fields}
      />);
  }

  render() {
    const {
      data,
      limit,
      offset,
      loading,
    } = this.state;
    const { paginate, search, showEmptyRows } = this.props;
    let emptyRows = 0;
    if (showEmptyRows) {
      emptyRows = limit - Math.min(limit, data.length - offset * limit);
    }
    const searchComponent = (search) ? this.renderSearch() : null;
    return (
      <div>
        {searchComponent}
        <DataTable
          header={ClientsTable.getTableHeader()}
          renderRows={this.renderRows}
          emptyRows={emptyRows}
          paginate={paginate}
          renderPagination={this.renderPagination}
          loading={loading}
          empty={data.length === 0}
        />
      </div>
    );
  }
}


ClientsTable.propTypes = propTypes;
ClientsTable.defaultProps = defaultProps;
export default withAuth(ClientsTable);
