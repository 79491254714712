import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import withAuth from '../consumers/withAuth';
import api from '../services/api';

const styles = theme => ({
  list: {
    marginTop: theme.spacing.unit,
  },
});

const propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

class FormsPage extends React.Component {
  async componentWillMount() {
    const { auth } = this.props;
    await api.clients.get({}, auth.getToken());
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.list}>
        <h1>
          Hello There, you are logged in and this is the Forms page.
        </h1>
      </div>
    );
  }
}


FormsPage.propTypes = propTypes;
export default withStyles(styles, { withTheme: true })(withAuth(FormsPage));
