import React from 'react';
import {
  Dashboard,
  Group,
  Assignment,
} from '@material-ui/icons';
import {
  PATHS,
  DASHBOARD,
  CLIENTS,
  FORMS,
} from '../constants/paths';

const routes = PATHS.map((item) => {
  let icon = null;
  switch (item.key) {
    case DASHBOARD:
      icon = <Dashboard />;
      break;
    case CLIENTS:
      icon = <Group />;
      break;
    case FORMS:
      icon = <Assignment />;
      break;
    default:
      icon = null;
      break;
  }
  return Object.assign({ icon, external: false }, item);
});

export default routes;
